import { navigate, RouteComponentProps, useLocation } from '@reach/router'
import {
  BookingBenefits,
  //Icon,
  BookingEngine,
  //SocialSharing,
  Button,
  Carousel,
  Container,
  Error,
  FormMessage,
  HeadingListing,
  ImageWrapper,
  Label,
  MessageColor,
  Modal,
  OptionWithTitle,
  ProfileSummary,
  RangeDates,
  SelectWithTitles,
  theme,
  VehicleDetails,
  VehicleFeatures,
  VehicleReviews,
  VehicleSummary
} from '@rmr/components'
import {
  PlaceType,
  RequestReservationMutation,
  useAuth,
  useMeQuery,
  useRequestReservation,
  useVehicle,
  useVehicleAvailabilityQuery,
  validationMessages
  //UserFieldsFragment
} from '@rmr/controllers'
import { Amount } from '@rmr/helpers'
import moment, { Moment } from 'moment'
import { parse, ParsedQuery } from 'query-string'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
//import { emailSharer, facebookSharer, twitterSharer } from '../../common/constants'
import Skeleton from 'react-loading-skeleton'
import { Box, Flex, SxStyleProp } from 'rebass'
import useMedia from 'use-media'
import { languages } from '../../common/i18n'
import LoginForm from '../LoginForm'
import { SearchBarVariant } from '../SearchBar'
import SEO from '../Seo'
import TripDates from '../TripDates'
import VehicleMap from './VehicleMap'
import { fixImageUrl } from '../../utils/fixImageUrl'

const containerStyles: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%'
}

const rangeDatesStyles: SxStyleProp = {
  '.DateRangePickerInput': {
    width: '100%',
    justifyContent: 'center'
  },
  '.DateInput': {
    width: 'calc(50% - 12px)'
  },
  '.DateInput_input': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Montserrat, sans-serif',
    color: '#494949'
  }
}

const stateDateWrapper = (date) => date

interface QueryParams extends ParsedQuery {
  startDate?: string
  endDate?: string
}

interface VehicleListingProps extends RouteComponentProps {
  vehicleId?: string
}

export const VehicleListing: React.FC<VehicleListingProps> = ({ vehicleId, ...props }: VehicleListingProps) => {
  const isMobile = useMedia({ maxWidth: theme.breakpoints[0] }) // 576px
  const location = useLocation()
  const [showMap, setShowMap] = useState(false)
  const { isAuthed, loading: loadingAuth } = useAuth()
  const { data: user, loading: loadingUSer } = useMeQuery({ skip: loadingAuth || !isAuthed })

  const [loginModal, setLoginModal] = React.useState(false)
  const [goToQuote, setGoToQuote] = React.useState(false)
  const formButtonRef = useRef<null | HTMLButtonElement>(null)

  const { startDate: startdate = '0', endDate: enddate = '0' } = parse(location.search) as QueryParams

  const { listing: api, loading: loadingVehicle } = useVehicle(vehicleId)

  const [error, setError] = useState('')
  const [{ startDate, endDate }, setDates] = useState({
    startDate: +startdate ? moment.unix(+startdate) : undefined,
    endDate: +enddate ? moment.unix(+enddate) : undefined
  })

  const onDatesChange = ({ startDate, endDate }) => {
    setError('')
    clearErrors()
    setValue('startDate', startDate)
    setValue('endDate', endDate)
    setDates({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate)
    })
  }

  const onSuccess = (response: RequestReservationMutation, needsAuth?: boolean, needsApproval?: boolean) => {
    if (needsAuth) {
      setLoginModal(true)
      return
    }
    if (needsApproval) {
      navigate('/app/approval-to-drive/')
      return
    }
    const { requestReservation } = response
    if (requestReservation.__typename === 'Reservation') {
      navigate(`/app/booking/quote/${requestReservation.id}`)
    }
  }

  const onError = (err) => {
    console.log(err)
  }

  const { onSubmit, submitting, submitResult, register, errors, clearErrors, setValue, getValues } =
    useRequestReservation({
      vehicleId,
      onError,
      onSuccess,
      startDate,
      endDate,
      isApprovedToDrive: isAuthed && user && user.me.profile.isApprovedDriver,
      isAuthed
    })

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    onSubmit(e)
  }

  const handleLogin = useCallback(() => {
    setLoginModal(false)
    setGoToQuote(true)
  }, [setLoginModal])

  const getTotalPrice = React.useCallback(() => {
    const form = getValues()

    if (api && api.pricePerDay) {
      const duration = form.endDate && form.startDate ? form.startDate.diff(form.endDate, 'days') * -1 : 3
      let price = api.pricePerDay.amountFloat * duration * 100

      if (form.locationId) {
        if (form.locationId === 'dropoff') {
          if (api.deliveryFeeRenter) {
            price = price + api.deliveryFeeRenter.amountFloat * 100
          }
        } else {
          const place = api.addresses.find((address) => address.id === form.locationId)
          if (place) {
            price = price + place.deliveryFee
          }
        }
      }

      return { duration, priceTotal: new Amount(price).format(0) }
    } else {
      return undefined
    }
  }, [api, getValues])

  useEffect(() => {
    if (goToQuote && user && user.me.__typename === 'User' && formButtonRef && formButtonRef.current) {
      formButtonRef.current.click()
    }
  }, [goToQuote, user, formButtonRef])

  useEffect(() => {
    register('startDate')
    if (startDate) setValue('startDate', startDate)
    register('endDate')
    register('locationId')

    if (endDate) setValue('endDate', endDate)
  }, [endDate, register, setValue, startDate])

  const places: OptionWithTitle[] = useMemo(() => {
    if (!api.addresses) return []

    const deliveryLocations = api.addresses.filter((address) => address.type !== PlaceType.Pickup)

    const pickupLocations = api.addresses.filter((address) => address.type === PlaceType.Pickup)

    const deliveryOptions = deliveryLocations.map((address) => ({
      label: `${address.name}`,
      value: address.id,
      icon: 'mapLocation',
      description: address.deliveryFeeAmount.formatted
    }))

    if (api.is_deliver_renter) {
      deliveryOptions.push({
        label: `Vehicle Drop-off - ${api.deliveryFeeRenter.formatted}`,
        value: 'dropoff',
        icon: 'mapLocation',
        description: 'Owner will deliver vehicle to your location'
      })
    }

    return [
      {
        title: languages.en.vehicleListing.pickupTitle,
        options: pickupLocations.map((address) => ({
          label: `${address.suburb}, ${address.city}, ${address.postalCode}`,
          value: address.id,
          icon: 'mapLocation',
          description: address.deliveryFeeAmount.formatted
        }))
      },
      {
        title: languages.en.vehicleListing.deliveryTitle,
        options: deliveryOptions
      }
    ]
  }, [api.addresses])

  const loading = loadingVehicle || loadingUSer

  const address = api.addresses?.find(({ type }) => type === PlaceType.Pickup)
  const hasAddress = !!address && Object.keys(address).length > 0
  const { priceTotal, duration } = getTotalPrice() || {}

  return (
    <React.Fragment>
      {loading ? null : (
        <SEO
          path={`/app/listing/${vehicleId}`}
          title={`${api.details.make} ${api.details.year} rental in ${address?.city ?? ''} ${
            address?.country ?? 'South Africa'
          } - RentMyRide`}
          description={`Need a ${api.category.name} car rental for your next trip? Book a ${api.details.make} on RentMyRide today!`}
        />
      )}
      <Box as="section">
        <Container maxWidth={'1620px'}>
          <Flex flexDirection={['row']} flexWrap="wrap">
            <Box
              order={[1, 1, 2, 1]}
              width={[1, null, 'auto', 2 / 12]}
              flex={[null, null, 1, 'unset']}
              sx={{
                borderColor: 'borderLight',
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderBottomWidth: [1, 1, 0],
                borderRightWidth: [0, 0, 1]
              }}
              pr={[0, 0, 2, 2]}
              py={[2, 2, 2, 5]}
            >
              <ProfileSummary
                loading={loading}
                name={api.user ? api.user.fullName : null}
                profileImageUrl={api.user ? fixImageUrl(api.user.avatar) : null}
                dateJoined={api.user ? api.user.joinDate : null}
                {...(hasAddress
                  ? {
                      locationCity: address.city,
                      locationCountry: address.country,
                      locationProvince: address.state
                    }
                  : {})}
              />
              <VehicleSummary
                reviewsCount={api.stats?.reviews}
                tripsCount={api.stats?.trips}
                averageRating={Math.ceil(api.stats?.rating)}
                loading={loading}
                sx={{ mt: [4, 0] }}
              />
            </Box>
            <Box
              order={[2, 2, 1, 2]}
              width={[1, 1, 1, 'auto']}
              px={[1, 6]}
              pt={[3]}
              pb={[0, 0, 0, 3]}
              flex={['1 0 auto', null, null, 1]}
              overflow={['hidden', 'unset']}
            >
              <HeadingListing headline={api.name} subtitle={api.category?.name} />
              {!loading ? (
                <Box sx={{ position: 'relative', width: '100%', pt: `${100 / 1.67}%` }}>
                  <Box sx={{ zIndex: showMap ? -1 : 1, opacity: showMap ? 0 : 1, ...containerStyles }}>
                    <Carousel variant="vehicleListingGallery">
                      {api.images &&
                        api.images.map((i) => {
                          return (
                            <Box sx={{ borderRadius: '4px', overflow: 'hidden', px: 1 }} key={i.id}>
                              <ImageWrapper key={i.id} src={i.file.url} alt={api.name} />
                            </Box>
                          )
                        })}
                    </Carousel>
                  </Box>
                  <Box sx={{ zIndex: showMap ? 1 : -1, opacity: showMap ? 1 : 0, ...containerStyles }} bg="orangered">
                    <VehicleMap vehiclePlaces={api.addresses} />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <ImageWrapper src={''} alt="placeholder" />
                </Box>
              )}
              <Flex sx={{ gap: 1, mt: 1 }}>
                <Box flexBasis="50%">
                  {!loading ? (
                    <Button variant={showMap ? 'gray' : 'primary'} fullWidth onClick={() => setShowMap(false)}>
                      {languages.en.vehicleListing.galleryButtonText}
                    </Button>
                  ) : (
                    <Skeleton style={{ width: '100%', height: '50px' }} />
                  )}
                </Box>
                <Box flexBasis="50%">
                  {!loading ? (
                    <Button variant={showMap ? 'primary' : 'gray'} fullWidth onClick={() => setShowMap(true)}>
                      {isMobile
                        ? languages.en.vehicleListing.mapButtonTextMobile
                        : languages.en.vehicleListing.mapButtonText}
                    </Button>
                  ) : (
                    <Skeleton style={{ width: '100%', height: '50px' }} />
                  )}
                </Box>
              </Flex>
              <VehicleDetails
                vehicle={api}
                headlineDetails={languages.en.vehicleListing.detailsHeadlineDetails}
                headlineDescription={languages.en.vehicleListing.detailsHeadlineDescription}
                loading={loading}
              />
              <VehicleFeatures
                vehicle={api}
                headline={languages.en.vehicleListing.featuresHeadline}
                loading={loading}
              />

              {api.stats?.reviews >= 0 ? (
                <>
                  <Box sx={{ borderBottom: `1px solid ${theme.colors.borderLight}` }} mt={[4]} mb={[8]} />
                  <VehicleReviews
                    vehicleId={api.id}
                    headline={languages.en.vehicleListing.reviewsHeadline}
                    reviewsCount={api.stats?.reviews}
                    averageRating={api.stats?.rating ? Math.ceil(api.stats.rating) : undefined}
                    showMoreButtonText={languages.en.vehicleListing.showMoreButtonText}
                    {...api.reviews}
                  />
                </>
              ) : null}
              <Box
                sx={{ borderBottom: `1px solid ${theme.colors.borderLight}`, borderBottomWidth: [1, 1, 1, 0] }}
                mt={[4]}
                mb={[0]}
              />
            </Box>
            <Flex
              order={[3, 3, 3, 3]}
              width={[1, 450]}
              maxWidth={450}
              mx="auto"
              flexDirection={['column']}
              justifyContent={['flex-start']}
              alignItems={['center']}
              py={[2, 2, 2, 5]}
              pl={[0, 3, 3]}
            >
              <BookingEngine
                width={[1]}
                mb={[4]}
                mt={[3, 0]}
                vehicleId={api.id}
                duration={duration}
                pricePerDay={api.pricePerDay?.formattedNoDecimals}
                priceTotal={priceTotal}
                minRentalPeriod={api.minimum_days}
                priceDeposit={0}
                priceInsurance={0}
                loading={loading}
              >
                <Box as="form" onSubmit={handleSubmit} width={1}>
                  {!loading ? (
                    <>
                      <Label mb={1}>{languages.en.vehicleListing.tripDatesLabel}</Label>
                      <TripDates
                        vehicleId={vehicleId}
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={onDatesChange}
                        minimumDays={api.minimum_days}
                        maximumDays={api.maximum_days}
                        onError={(errorMessage) => setError(errorMessage)}
                      />
                    </>
                  ) : (
                    <Skeleton style={{ width: '100%', height: '50px' }} />
                  )}

                  {error && <Error sx={{ my: 1 }}>{error}</Error>}
                  {(errors.startDate || errors.endDate) && <Error sx={{ my: 1 }}>{validationMessages.en.dates}</Error>}
                  {!loading ? (
                    <Box mb={[2, 0]} mt={[2]}>
                      <Label mb={1}>{languages.en.vehicleListing.pickupReturnLocationLabel}</Label>
                      <SelectWithTitles
                        filterName={languages.en.vehicleListing.pickupReturnLocationFilter}
                        optionsWithTitle={places}
                        onSet={(paymentMethod) => {
                          clearErrors('locationId')
                          setValue('locationId', paymentMethod)
                        }}
                        sx={{ flex: '1 0 auto', mr: [0, 2], height: '50px', width: '100%' }}
                        buttonSx={{ height: '100%' }}
                      />
                    </Box>
                  ) : (
                    <Skeleton style={{ width: '100%', height: '50px' }} />
                  )}
                  {errors.locationId && (
                    <Error sx={{ my: 1 }}>{languages.en.vehicleListing.pickupReturnLocationError}</Error>
                  )}
                  {!loading ? (
                    <Button
                      ref={(button) => {
                        if (button) {
                          formButtonRef.current = button
                        }
                      }}
                      type="submit"
                      my={[2]}
                      fullWidth={true}
                      label={languages.en.vehicleListing.buttonLabel}
                      disabled={submitting || !!error || api?.user?.isBlocked}
                      sx={{ py: [2, 2] }}
                    />
                  ) : (
                    <Box my={[2]}>
                      <Skeleton style={{ width: '100%', height: '60px' }} />
                    </Box>
                  )}
                </Box>
                <FormMessage
                  showMessage={submitResult.submitted}
                  message={submitResult.message}
                  color={`${submitResult.error ? 'statusError' : 'statusSuccess'}` as MessageColor}
                />
                <FormMessage
                  showMessage={api?.user?.isBlocked}
                  message={languages.en.vehicleListing.unavailable}
                  color={'statusError' as MessageColor}
                />
              </BookingEngine>
              <BookingBenefits {...api.benefits} loading={loading} />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Modal isModalOpen={loginModal} closeModal={() => setLoginModal(false)}>
        <LoginForm callback={handleLogin} />
      </Modal>
    </React.Fragment>
  )
}

export default VehicleListing
