import { useGetVehicleQuery, Vehicle } from '../lib/generated/generated'

export const useVehicle = (vehicleId: string) => {
  const { data, loading } = useGetVehicleQuery({
    variables: {
      id: vehicleId
    }
  })

  return {
    listing: {
      ...((data?.vehicle ?? {}) as Vehicle),
      benefits: {
        items: [
          {
            headline: 'Free cancellation',
            description: 'You can cancel your trip for free, up to 24 hours before it starts.',
            icon: 'award'
          },
          {
            headline: 'Insurance provided by',
            description: 'Paradigm',
            icon: 'shieldCheck'
          }
        ]
      }
    },
    loading
  }
}
