import { HeaderProps } from '@rmr/components'
import { useWpMenu } from '../hooks/useWpMenu'
const useHeaderMenu = (): HeaderProps => {
  const menus = useWpMenu()
  return {
    homeLink: '/',
    menuItems: menus['primary'],
    navButtons: [
      { label: 'Sign Up', to: '/app/signup/' },
      { label: 'Login', to: '/app/login/' }
    ]
  }
}

export default useHeaderMenu
